<template>
  <div class="page">
    <div class="page-header">属地报告</div>
    <!-- 筛选框 -->
    <el-card shadow="never" class="card1 card">
      <el-input
        style="width: 240px"
        v-model="secrchValue"
        clearable
        placeholder="请输入报告名称"
      ></el-input>
      <el-select
        style="width: 190px; margin-left: 32px"
        v-model="reportVlaue"
        placeholder="请选择报告类型"
        clearable
      >
        <el-option
          v-for="item in reportTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <span class="timefont" style="margin-left: 32px">操作时间</span>
      <el-date-picker
        style="height: 36px"
        class="m-l"
        v-model="timeArr"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
      ></el-date-picker>
      <div class="btn2 m-l" @click="exportTerritorialReport">
        <img src="@/assets/img/icon/topJT.png" alt="" />
        数据导出
      </div>
      <div class="reset" @click="remake">重置</div>
      <div class="btn" @click="seachTerritorialReportList">查询</div>
    </el-card>
    <!-- 表格 Or 新增报告 -->
    <el-card shadow="never" class="m-t card">
      <div class="xzbg">
        <div class="xzbgbtn" @click="addBG">新增报告</div>
      </div>
      <!-- 表格 -->
      <el-table
        v-loading="errorloading"
        class="m-t"
        stripe
        :data="tableData"
        :header-cell-style="{
          'text-align': 'center',
          background: '#F6F7FA',
          color: '#333',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="
          width: 100%;
          text-align: center;
          border: 1px solid #e4e6ec;
          border-bottom: none;
        "
      >
        <el-table-column
          label="序号"
          :index="indexMethod"
          type="index"
          width="80"
          fixed
        ></el-table-column>
        <el-table-column prop="name" label="报告名称" width="180"></el-table-column>
        <el-table-column label="报告类型" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.reportType == 1">日报</span>
            <span v-if="scope.row.reportType == 2">月报</span>
            <span v-if="scope.row.reportType == 7">专项行动</span>
          </template>
        </el-table-column>
        <el-table-column prop="createUser" label="操作人员" width="160"></el-table-column>
        <el-table-column prop="timeA" sortable label="报告时间">
          <template slot-scope="scope">
            <span>{{ scope.row.reportStartTime }} ~ {{ scope.row.reportEndTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="generateTime" sortable label="生成时间"></el-table-column>
        <el-table-column label="更多">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              style="color: #2e59ec"
            >
              下载导出&nbsp;&nbsp;({{ scope.row.numDownloads || "0" }}次)
            </el-button>
            <div class="table_line"></div>
            <el-button
              type="text"
              style="color: #ea3342"
              size="small"
              @click="openDeleteTerritorialReport(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination
        :pageNumber="page.pageNumber"
        :pageSize="page.pageSize"
        :total="total"
        @SizeChange="SizeChange"
        @CurrentChange="CurrentChange"
      />
    </el-card>
    <!-- 新建方案 -->
    <el-dialog
      :visible.sync="newPlanData.dialogTableVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="630px"
    >
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">新增报告</span>
      </div>
      <div class="dialog-body" v-loading="newPlanData.loading">
        <el-row :gutter="60">
          <el-col :span="13">
            <div class="dialog-body-desc">
              <span class="symbol">*</span>
              报告名称
            </div>
            <el-input
              class="m-t"
              v-model="newPlanData.data.name"
              placeholder="请输入内容"
            ></el-input>
          </el-col>
          <el-col :span="9">
            <div class="dialog-body-desc">
              <span class="symbol">*</span>
              报告类型
            </div>
            <div class="m-t">
              <el-radio v-model="newPlanData.data.radio" label="1">日报</el-radio>
              <el-radio v-model="newPlanData.data.radio" label="3">月报</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 24px">
          <el-col>
            <div class="dialog-body-desc">
              <span class="symbol">*</span>
              开始时间
            </div>
            <el-date-picker
              style="height: 36px"
              class="m-t"
              v-model="newPlanData.data.timeArr"
              type="daterange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            />
          </el-col>
        </el-row>
        <el-row style="margin-top: 32px">
          <el-col>
            <div class="dialog-body-desc">
              <span class="symbol">*</span>
              数据类型
            </div>
            <el-radio-group v-model="newPlanData.data.radio3" style="margin-top: 24px">
              <!-- <el-radio label="1">专项行动</el-radio> -->
              <el-radio label="2">预警信息</el-radio>
              <el-radio label="3">有害信息</el-radio>
              <el-radio label="4">监测方案</el-radio>
              <el-radio label="5">错误表述</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newPlanData.dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="newPlanYes">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除二次确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="deleteTerritorialReport"></deleteDialog>
    <!-- 导出进度条 -->
    <ExportDialog ref="exportDialog"></ExportDialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import Territorialreport from "@/api/territorialreport/index";
import deleteDialog from "@/components/deleteDialog.vue";
import ExportDialog from "@/components/exportDialog.vue";

export default {
  data() {
    return {
      // 删除
      deleteDialogVisible: false,
      // 加载效果
      errorloading: false,
      secrchValue: "", // 报告名称
      // 报告类型list
      reportTypes: [
        {
          value: 1,
          label: "日报",
        },
        {
          value: 2,
          label: "月报",
        },
        {
          value:7,
          label:"专项行动"
        }
      ],
      reportVlaue: "", //报告类型
      timeArr: null, // 时间
      // 表格数据
      tableData: [],
      //分页
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      // 新增报告
      newPlanData: {
        dialogTableVisible: false,
        loading: false,
        data: {
          name: "", //报告名字
          radio: "1", // 报告类型
          radio2: false, // 专项行动报告
          radio3: "2",
          timeArr: null,
          reportVlaue: "",
        },
      },
    };
  },
  components: {
    Pagination,
    deleteDialog,
    ExportDialog,
  },
  methods: {
    // 导出报告列表
    exportTerritorialReport() {
      let data = {
        limit: this.page.pageSize,
        page: this.page.pageNumber,
      };
      if (this.secrchValue != "") {
        data.name = this.secrchValue;
      }
      if (this.reportVlaue != "") {
        data.reportType = this.reportVlaue;
      }
      if (this.timeArr) {
        data.reportStartTime = this.timeArr[0];
        data.reportEndTime = this.timeArr[1];
      }
      let textName = "属地报告数据信息.xlsx";
      let url = "module-web/orgReport/exportExcel";
      this.$parent.downloadPublicFun({
        url,
        data,
        textName,
      });
    },
    //删除属地报告
    async deleteTerritorialReport(item) {
      const res = await Territorialreport.deleteTerritorialReport(item.item.id);
      if (res.data.code == 200) {
        this.$message.success("删除报告成功");
        this.getTerritorialReportList();
      }
    },
    // 打开删除弹窗
    openDeleteTerritorialReport(item) {
      item.message = "此报告";
      this.$refs.openDialog.openDialog(item);
    },
    // 重置
    remake() {
      this.secrchValue = "";
      this.reportVlaue = "";
      this.timeArr = null;
      this.page = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.total = 0;
      this.getTerritorialReportList();
    },
    // 查询属地报告列表
    seachTerritorialReportList() {
      this.page = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.total = 0;
      this.getTerritorialReportList();
    },
    // 获取属地报告列表
    async getTerritorialReportList() {
      this.errorloading = true;
      let data = {
        limit: this.page.pageSize,
        page: this.page.pageNumber,
      };
      if (this.secrchValue != "") {
        data.name = this.secrchValue;
      }
      if (this.reportVlaue != "") {
        data.reportType = this.reportVlaue;
      }
      if (this.timeArr) {
        data.startCreateTime = this.timeArr[0];
        data.endCreateTime = this.timeArr[1];
      }
      const res = await Territorialreport.getTerritorialReportList(data);
      if (res && res.data && res.data.code == 200) {
        this.total = res.data.data.totalCount / 1;
        this.tableData = res.data.data.list;
        this.errorloading = false;
      } else {
        this.tableData = [];
        this.errorloading = false;
      }
    },
    // 下载计次
    async downloadCount(data) {
      const res = await Territorialreport.downloadCount(data);
      if (res.data.code == 200) {
        this.getTerritorialReportList();
      }
    },
    // 下载报告
    handleClick(item) {
      this.downloadCount(item.id);
      this.$refs.exportDialog.exportVisible = true;
      this.$refs.exportDialog.downloadProgress();
      let link = document.createElement("a");
      link.href = item.reportUrl;
      link.click();
      link.remove();
      setTimeout(() => {
        clearTimeout(this.$refs.exportDialog.timer);
        this.$refs.exportDialog.wite = false;
        this.$refs.exportDialog.percentage = 100;
      }, 1000);
    },
    // 新增报告 弹窗
    addBG() {
      this.newPlanData.data = {
        name: "",
        radio: "1",
        radio2: false,
        radio3: "2",
        timeArr: null,
        reportVlaue: "",
      };
      this.newPlanData.dialogTableVisible = true;
    },
    // 新增报告里选不选 专项行动报告的按钮
    changeradio2() {
    },
    // 新增报告 确定按钮
    async newPlanYes() {
      if (this.newPlanData.data.name == "") {
        this.$message.warning("请输入报告名称！");
        return false;
      }
      if (!this.newPlanData.data.timeArr) {
        this.$message.warning("请选择报告开始时间！");
        return false;
      }
      let data = {};
      data.name = this.newPlanData.data.name;
      data.reportType = this.newPlanData.data.radio / 1;
      data.reportStartTime = this.newPlanData.data.timeArr[0];
      data.reportEndTime = this.newPlanData.data.timeArr[1];
      data.sourceType = this.newPlanData.data.radio3 / 1;
      this.newPlanData.loading = true;
      const res = await Territorialreport.addTerritorialReportList(data);
      if (res.data.code == 200) {
        this.newPlanData.loading = false;
        this.newPlanData.dialogTableVisible = false;
        this.$message.success("新增报告成功！");
        this.getTerritorialReportList();
      } else {
        this.newPlanData.loading = false;
      }
    },
    // 分页
    SizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNumber = 1;
      this.getTerritorialReportList();
    },
    CurrentChange(val) {
      this.page.pageNumber = val;
      this.getTerritorialReportList();
    },
    // 表格序号
    indexMethod(index) {
      return index + this.page.pageNumber * this.page.pageSize - this.page.pageSize + 1;
    },
  },
  created() {
    this.getTerritorialReportList();
  },
};
</script>

<style lang="scss" scoped>
.reset {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 16px;
  cursor: pointer;
}
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
::v-deep .el-range-separator {
  width: 24px;
}
.card1 {
  ::v-deep .el-input__inner {
    height: 36px;
  }
}
.card {
  .timefont {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .btn {
    width: auto;
    padding: 8px 14px 8px 14px;
    background: #f58030;
    color: #ffffff;
    border-radius: 4px;
    float: right;
    cursor: pointer;
  }
  .btn2 {
    width: auto;
    padding: 8px 14px 8px 14px;
    background: #fff1e8;
    color: #ed731f;
    border-radius: 4px;
    float: right;
    cursor: pointer;
  }
  .m-l {
    margin-left: 16px;
  }

  .xzbg {
    width: 100%;
    height: 36px;
    .xzbgbtn {
      width: auto;
      padding: 8px 14px 8px 14px;
      background: #f58030;
      color: #ffffff;
      border-radius: 4px;
      float: right;
      cursor: pointer;
    }
  }
  //表格内容颜色
  ::v-deep .el-table__body tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  ::v-deep .el-table__body tbody tr:nth-child(even) td {
    background-color: #f6f7fa;
  }
}
.m-t {
  margin-top: 16px;
}
.dialog-body-desc {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #333333;
  position: relative;
  .symbol {
    color: red;
    position: absolute;
    left: -10px;
  }
}
.table_line {
  width: 1px;
  height: 14px;
  background: #e4e6ec;
  margin: 0 16px;
  display: inline-block;
  vertical-align: middle;
}
</style>
