// 属地报告接口
import request from "../request.js";
// 获取属地报告数据列表
function getTerritorialReportList(data) {
  return request({
    url: "/module-web/orgReport/list",
    method: "post",
    data,
  });
}
// 新增属地报告
function addTerritorialReportList(data) {
  return request({
    url: "/module-web/orgReport/save",
    method: "post",
    data,
  });
}
// 删除属地报告
function deleteTerritorialReport(data) {
  return request({
    url: "/module-web/orgReport/delete/" + data,
    method: "get",
  });
}
// 导出属地报告数据列表
function exportTerritorialReport(data) {
  return request({
    url: "/module-web/orgReport/exportExcel",
    method: "post",
    data,
  });
}
// 下载计次
function downloadCount(data) {
  return request({
    url: "/module-web/orgReport/timingDownload/" + data,
    method: "post",
    data: {},
  });
}

export default {
  getTerritorialReportList,
  addTerritorialReportList,
  deleteTerritorialReport,
  exportTerritorialReport,
  downloadCount,
};
